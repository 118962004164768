/** @jsx jsx */
import { jsx } from "theme-ui"
import { RiSendPlane2Line } from "react-icons/ri"

import Layout from "../components/layout"
import SEO from "../components/seo"

const contactStyles = {
  contactLayout: {
    backgroundColor: "coverBackground",
    pb: 5,
  },
  container: {
    maxWidth: "786px",
    m: "0 auto",
    pt: [3, 4, 4, 5],
    px: [3, 4, 4, 5],
  },
  heading: {
    fontWeight: 900,
    fontSize: [4, 5],
    mb: 0,
  },
}

const Contact = () => (
  <Layout className="contact-page" sx={contactStyles.contactLayout}>
    <SEO
      title="Contact • Stackrole"
      description="Get in touch with Stackrole"
    />
    <div sx={contactStyles.container}>
      <h1 sx={contactStyles.heading}>Get in Touch</h1>
      <div className="description">
        <p>
          Got a burning question regarding <b>Jamstack</b>, Need a new{" "}
          <b>Website</b>, or just want to talk in general. We always welcome a
          good conversation.
        </p>
        <p>
          Just send us a message using the form below or you can send us a DM on{" "}
          <a href="https://twitter.com/stackrole">twitter</a>.
        </p>
      </div>
      <form
        className="contact-form"
        action="/thanks"
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label htmlFor="name">
            Name
            <input type="text" name="name" id="name" required />
          </label>
        </p>
        <p>
          <label htmlFor="email">
            Email
            <input type="email" name="email" id="email" required />
          </label>
        </p>
        <p>
          <label htmlFor="subject">
            Subject
            <input type="text" name="subject" id="subject" required />
          </label>
        </p>
        <p>
          <label htmlFor="message">
            Message<textarea name="message" id="message" required></textarea>
          </label>
        </p>
        <p className="text-align-right">
          <button className="button" type="submit">
            Send Message <RiSendPlane2Line className="icon -right" />
          </button>
        </p>
      </form>
    </div>
  </Layout>
)

export default Contact
